
import { Inject } from 'inversify-props';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import { Component, Mixins } from 'vue-property-decorator';
import DownloadReportForm, { DownloadReportControlMixin } from '@/modules/common/components/download-report-form.vue';
import { RankingDownloadExcelForm } from '../ranking-api.service';
import RankingService, { RankingServiceS } from '../ranking.service';

@Component({
    components: {
        DownloadReportForm,
    },
})
export default class RankingDownloadExcelPage extends Mixins(DownloadReportControlMixin) {
    @Inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @Inject(RankingServiceS)
    private rankingService!: RankingService;

    private isLoading = false;

    public get attrs() {
        const { filters, buttons } = this;
        const { isLoading } = this;

        return {
            filters,
            buttons,
            dataType: 'Guest Reviews',
            isLoading,
        };
    }

    private get compsetOptions() {
        return (this.compsetsService.compsets || [])
            .map(compset => ({
                name: compset.name,
                value: compset.id,
            }));
    }

    private get filters() {
        const { id: currentCompsetId = null } = this.compsetsService.currentCompset || {};

        return [
            {
                label: 'Compset',
                key: 'compsetId',
                options: this.compsetOptions,
                default: currentCompsetId,
            },
        ];
    }

    private get buttons() {
        return [
            {
                label: 'Download now',
                onClick: this.requestDocument.bind(this),
            },
        ];
    }

    private async requestDocument(form: RankingDownloadExcelForm) {
        try {
            this.isLoading = true;
            await this.rankingService.downloadExcel(form);
            this.isLoading = false;
            this.closeForm();
        } catch (_) {
            this.triggerFormError('There is no data. Try change the filters.');
        }
    }
}
