var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ModalWrapper',{ref:"modal",staticClass:"download-report-form",attrs:{"width":"650px"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"title"},[_vm._v(" Data Export ")])]},proxy:true},{key:"main",fn:function(){return [_c('div',{staticClass:"download-report-form__container"},[_c('div',{class:[
                    'download-report-form__frame',
                    'download-report-form__frame--properties',
                ],attrs:{"data-title":"Properties"}},[_c('div',{staticClass:"download-report-form__property-field"},[_c('div',[_vm._v(" Data type ")]),_c('div',[_vm._v(" "+_vm._s(_vm.dataType)+" ")])]),(_vm.properties.length)?_vm._l((_vm.properties),function(property){return _c('div',{key:property.key,staticClass:"download-report-form__property-field"},[_c('div',[_vm._v(" "+_vm._s(property.label)+" ")]),_c('div',[_c(property.component,_vm._g(_vm._b({tag:"component",model:{value:(_vm.formWatcher[property.key]),callback:function ($$v) {_vm.$set(_vm.formWatcher, property.key, $$v)},expression:"formWatcher[property.key]"}},'component',property.props || {},false),property.handlers || {}))],1)])}):_vm._e()],2),(_vm.customColumns.length)?_c('div',{class:[
                    'download-report-form__frame',
                    'download-report-form__frame--checkbox',
                ],attrs:{"data-title":"Custom Columns"}},[_c('div',_vm._l((_vm.customColumns),function(column){return _c('CustomCheckbox',{key:column.key,attrs:{"label":column.label,"disabled":column.disabled},model:{value:(_vm.columnsWatcher[column.key]),callback:function ($$v) {_vm.$set(_vm.columnsWatcher, column.key, $$v)},expression:"columnsWatcher[column.key]"}})}),1)]):_vm._e(),_c('div',{class:[
                    'download-report-form__frame',
                    'download-report-form__frame--filters',
                ],attrs:{"data-title":"Filters"}},[_c('div',[_vm._l((_vm.filters),function(filter,index){return [(!filter.divider)?[(!filter.component)?[(!filter.multiselect)?_c('CustomSelect',{key:filter.key,style:({ 'zIndex': Math.floor((_vm.filters.length - index) / 3)}),attrs:{"label":filter.label,"items":filter.options,"disabled":filter.disabled},model:{value:(_vm.formWatcher[filter.key]),callback:function ($$v) {_vm.$set(_vm.formWatcher, filter.key, $$v)},expression:"formWatcher[filter.key]"}}):_c('CustomMultiSelect',{key:filter.key,style:({ 'zIndex': Math.floor((_vm.filters.length - index) / 3)}),attrs:{"label":filter.label,"items":filter.options,"disabled":filter.disabled,"maxSelected":filter.maxSelected,"showButton":!filter.maxSelected,"emitValuesOnly":filter.valuesOnly,"filterSelectAll":filter.filterSelectAll},model:{value:(_vm.formWatcher[filter.key]),callback:function ($$v) {_vm.$set(_vm.formWatcher, filter.key, $$v)},expression:"formWatcher[filter.key]"}})]:_c(filter.component,_vm._b({key:filter.key,tag:"component",style:({ 'zIndex': Math.floor((_vm.filters.length - index) / 3)}),model:{value:(_vm.formWatcher[filter.key]),callback:function ($$v) {_vm.$set(_vm.formWatcher, filter.key, $$v)},expression:"formWatcher[filter.key]"}},'component',filter.props || {},false))]:_vm._l((filter.count),function(n){return _c('div',{key:index + '-' + n,staticClass:"download-report-form__divider"})})]})],2)]),(_vm.errorMessage || _vm.infoMessage)?_c('div',{class:{
                    'download-report-form__message-bar': true,
                    'download-report-form__message-bar--error': !!_vm.errorMessage,
                    'download-report-form__message-bar--info': !!_vm.infoMessage,
                },domProps:{"textContent":_vm._s(_vm.errorMessage || _vm.infoMessage)}}):_vm._e(),_c('div',{staticClass:"download-report-form__action-bar"},_vm._l((_vm.buttons),function(button){return _c('button',{key:button.label,staticClass:"btn btn_primary",attrs:{"disabled":_vm.isLoading || button.isDisabled},domProps:{"textContent":_vm._s(button.label)},on:{"click":function($event){return button.onClick(_vm.form)}}})}),0)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }